import React, {useState, useEffect} from "react";
import "../../css/CreateRecordMainContent.css"; // Import custom styles
import api from "../../helpers/API";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";

function ViewRecordMainContent() {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [error, setError] = useState("");
    const [arch_deaconry_id, setArchDeaconryId] = useState("");
    const [lease_no, setLeaseNo] = useState("");
    const [name, setName] = useState("");
    const [document_type, setDocumentType] = useState("");
    const [location, setLocation] = useState("");
    const [ground_rent, setGroundRent] = useState("");
    const [date_leased, setDateLeased] = useState("");
    const [expiry_date, setExpiryDate] = useState("");
    const [updated_by, setUpdatedBy] = useState("");
    const [path, setPath] = useState("");

    const [parishId, setParishId] = useState(null);
    const [subParishId, setSubParishId] = useState(null);
    const [folderId, setFolderId] = useState(null);


    const [loading, setLoading] = useState(false);

    const {id} = useParams();

    const fetchDependencies = async () => {
        setLoading(true);
        if (typeof id !== "undefined") {
            try {
                const response = await api("GET", "records/" + id);
                if (response.data.status) {
                    //   setArchDeaconries(response.data.data.arch_deaconries);
                    setArchDeaconryId(response.data.data.arch_deaconry.name ?? ' --');
                    setParishId(response.data.data.parish ? response.data.data.parish.name : ' --');
                    setSubParishId(response.data.data.sub_parish ? response.data.data.sub_parish.name : ' --');
                    setFolderId(response.data.data.folder.name ?? ' --');
                    // setArchDeaconryId(response.data.data.arch_deaconry_id);
                    setLeaseNo(response.data.data.lease_no ?? ' --');
                    setName(response.data.data.name ?? ' --');

                    setDocumentType(response.data.data.document_type ?? ' --');
                    setLocation(response.data.data.location ?? ' --');
                    setGroundRent(response.data.data.ground_rent ?? ' --');
                    setDateLeased(response.data.data.date_leased ?? ' --');
                    setExpiryDate(response.data.data.expiry_date ?? ' --');
                    setUpdatedBy(response.data.data.updated_by ?? ' --');


                    setPath(response.data.data.path);
                } else {
                    setError(response.data.message);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchDependencies();
    }, [id]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(URL.createObjectURL(file));
            setFileType(file.type);
        }
    };

    const isImage = (type) => type.startsWith("image/");
    const isPDF = (type) => type === "application/pdf";

    return (
        <div>
            {loading ? (
                <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{height: "80vh"}}
                >
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <div className="container mt-5 px-5">
                        <div className="row">
                            {/* Document Upload Section */}
                            <div className="col-md-7" style={{flex: "2", overflow: "hidden"}}>
                                <iframe
                                    src={path}
                                    style={{width: "100%", height: "100%", border: "none"}}
                                    title="PDF Preview"
                                ></iframe>
                            </div>
                            {/* Form Section */}
                            <div className="col-md-5" style={{flex: "1", padding: "10px", overflowY: "auto"}}>
                                <div className="card mt-2">
                                    <div className="card-body mt-5">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Arch deaconry</label>
                                                    <p>
                                                        <span className="">{arch_deaconry_id}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Parish</label>
                                                    <p>
                                                        <span className="">{parishId}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Sub Parish</label>
                                                    <p>
                                                        <span className="">{subParishId}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Folder</label>
                                                    <p>
                                                        <span className="">{folderId}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Lease no</label>
                                                    <p>
                                                        <span className="">{lease_no}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Name</label>
                                                    <p>
                                                        <span className="">{name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Type</label>
                                                    <p>
                                                        <span className="">{document_type}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Location</label>
                                                    <p>
                                                        <span className="">{location}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Ground rent</label>
                                                    <p>
                                                        <span className="">{ground_rent}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Date leased</label>
                                                    <p>
                                                        <span className="">{date_leased}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Expiry date</label>
                                                    <p>
                                                        <span className="">{expiry_date}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="fw-bold">Updated by</label>
                                                    <p>
                                                        <span className="">{updated_by}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ViewRecordMainContent;
