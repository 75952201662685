import React, {useState, useEffect, useContext} from "react";
import LogoText from "./LogoText";
import "../css/Sidebar.css";
import logoImage from "../images/logo.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "../css/DashboardPage.css";
import DashboardContext from "../pages/contexts/DashboardContext";
import api from "../helpers/API";

function Sidebar() {
    const {changeDashboardClass} = useContext(DashboardContext);
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;
    const [isSidebarOpen, setIsSidebarOpen] = useState(
        localStorage.getItem("isSidebarOpen") !== "false"
    );

    // State to store folders fetched from API
    const [folders, setFolders] = useState([]);

    // Fetch folders from API on component mount
    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const response = await api("GET", "arch-deaconries");
                if (response.data.status) {
                    console.log(response.data.data.listings);
                    setFolders(response.data.data.listings); // Correctly update state with fetched folders
                } else {
                    // setError('response.data.message'); // Placeholder for setting error state
                }
            } catch (error) {
                if (error.code === 'ERR_BAD_REQUEST') navigate('/');

                console.error("Failed to fetch folders:", error);
            }
        };

        fetchFolders();
    }, [navigate]); // Empty dependency array to run the effect only once

    // Determine if the current path is one of the folder paths
    const isCurrentPathAFolder = folders.some((folder) =>
        location.pathname.startsWith(folder.path)
    );
    const [showFoldersCard, setShowFoldersCard] = useState(isCurrentPathAFolder);

    useEffect(() => {
        localStorage.setItem("isSidebarOpen", isSidebarOpen.toString());
    }, [isSidebarOpen]);

    useEffect(() => {
        // Automatically open the folders list if the current path is one of the folders
        setShowFoldersCard(isCurrentPathAFolder);
    }, [location.pathname, isCurrentPathAFolder]); // Include isCurrentPathAFolder in the dependency array

    const toggleSidebar = () => {
        const newState = !isSidebarOpen;
        setIsSidebarOpen(newState);
        localStorage.setItem("isSidebarOpen", newState.toString());
        changeDashboardClass("dashboard-change");
    };

    const handleFolderClick = (id, title) => {
        navigate(`/arch-deaconry/${id}?title=` + title); // Assuming the route to navigate to is `/folder/:id`
    };

    return (
        <div
            className={`sidebar ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
            <div className="sidebar-header">
                {isSidebarOpen && (
                    <>
                        <img
                            className="logo-component-spacing"
                            src={logoImage}
                            alt="Logo"
                            style={{height: "50px", borderRadius: "20%"}}
                        />
                        <LogoText/>
                    </>
                )}
                <button
                    onClick={toggleSidebar}
                    className={`toggle-sidebar-btn ${
                        !isSidebarOpen ? "align-with-items" : ""
                    }`}
                    title={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
                >
                    {isSidebarOpen ? (
                        <i className="fas fa-angle-left" aria-hidden="true"></i>
                    ) : (
                        <i className="fas fa-angle-right" aria-hidden="true"></i>
                    )}
                </button>
            </div>
            <ul className="sidebar-content">
                <Link
                    to="/dashboard?title=Dashboard"
                    className={`sidebar-link ${isActive("/dashboard") ? "active" : ""}`}
                >
                    <li>
                        <i className="fas fa-tachometer-alt"></i>
                        {isSidebarOpen && " Dashboard"}
                    </li>
                </Link>
                <br/>
                <div
                    className={`sidebar-link ${
                        showFoldersCard || isCurrentPathAFolder ? "active" : ""
                    }`}
                    onClick={() => setShowFoldersCard(!showFoldersCard)}
                >
                    <li>
                        <i className="fas fa-folder"></i>
                        {isSidebarOpen && " Arch Deaconries"}
                        {showFoldersCard && (
                            <div className="folders-card">
                                {folders.map((folder) => (
                                    <div
                                        key={folder.name}
                                        className={`folder-item ${
                                            isActive(`/arch-deaconry/${folder.id}`) ? "active-folder" : ""
                                        }`} // Updated to check active state based on id
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFolderClick(folder.id, folder.name); // Updated to use id for navigation
                                        }}
                                    >
                                        <i className="fas fa-folder"></i> {folder.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </li>
                </div>
                <br/>
                <Link
                    to="/move-folder?title=Move Folder"
                    className={`sidebar-link ${isActive("/move-folder") ? "active" : ""}`}
                >
                    <li>
                        <i className="fas fa-file-export"></i>
                        {isSidebarOpen && " Move Folder"}
                    </li>
                </Link>
                <Link
                    to="/create-record"
                    className={`sidebar-link ${
                        isActive("/create-record") ? "active" : ""
                    }`}
                >
                    <li>
                        <i className="fas fa-plus-square"></i>
                        {isSidebarOpen && " Create Record"}
                    </li>
                </Link>
                <br/>
                <Link
                    to="/user-guide"
                    className={`sidebar-link ${isActive("/user-guide") ? "active" : ""}`}
                >
                    <li>
                        <i className="fas fa-info-circle"></i>
                        {isSidebarOpen && " User Guide"}
                    </li>
                </Link>
            </ul>
        </div>
    );
}


export default Sidebar;
