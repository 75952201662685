import React, {useState, useEffect} from "react";
import "../../css/DashboardPage.css";
import Sign from '../../components/DevSignature';

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import ViewRecordMainContent from "../contents/ViewRecordMainContent";
import DashboardContext from "./DashboardContext"; // Import the context
import "../../css/CreateRecordMainContent.css";

function ViewRecordPage() {
    const [dashboardClass, setDashboardClass] = useState("dashboard");

    useEffect(() => {
        // Load the dashboard class from localStorage when the component mounts
        const savedClass = localStorage.getItem("dashboardClass");
        if (savedClass) {
            setDashboardClass(savedClass);
        }
    }, []);

    const changeDashboardClass = () => {
        setDashboardClass((currentClass) => {
            const newClass =
                currentClass === "dashboard" ? "dashboard-change" : "dashboard";
            // Save the new class to localStorage
            localStorage.setItem("dashboardClass", newClass);
            return newClass;
        });
    };

    return (
        <DashboardContext.Provider value={{changeDashboardClass}}>
            <div className={dashboardClass}>
                <Sidebar/>
                <div className="dashboard-main">
                    <Header/>
                    <ViewRecordMainContent/>
                    <Sign/>

                </div>
            </div>
        </DashboardContext.Provider>
    );
}

export default ViewRecordPage;
