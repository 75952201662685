import axios from "axios";

// Ensure axios sends cookies with requests
axios.defaults.withCredentials = true;

async function api(request_type = "GET", endpoint, data = {}, headers = {}) {
    try {
        const base_url = process.env.REACT_APP_API_URL;

        // First, get the CSRF token by making a GET request to the CSRF cookie endpoint
        await axios.get(base_url + "sanctum/csrf-cookie");

        // Get the token from the session storage
        const token = sessionStorage.getItem("token");
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        const url = base_url + "api/v1/" + endpoint;
        console.log("REQUEST ENDPOINT: " + url);

        // Merge default headers with custom ones
        const defaultHeaders = {
            "Content-Type": "application/json",
        };
        headers = {...defaultHeaders, ...headers};

        // Make the API request and return the promise
        return axios({
            method: request_type,
            url: url,
            data: data,
            headers: headers,
            withCredentials: true,
        });
    } catch (e) {
        // Log the error for debugging
        console.error("API Error: ", e.response?.data || e.message);

        // Handle specific HTTP statuses (e.g., 401 Unauthorized)
        if (axios.isAxiosError(e)) {
            const status = e.response?.status || null;
            if (status === 401) {
                // Redirect to login if unauthorized
                window.location.href = "/login";
            }
        }

        // Rethrow the error to allow the caller to handle it
        throw e;
    }
}

export default api;
