import React from 'react';

const Pagination = ({ currentPage, links = [], onPageChange }) => {
  
  const handlePageChange = (link) => {
    // Extract the page number from the link URL
    const urlParams = new URLSearchParams(link.split('?')[1]);
    const page = urlParams.get('page');

    if (page) {
      // Call the onPageChange function passed from the parent component
      onPageChange(Number(page));
    }
  };

  return (
    <div>
      <ul className="pagination">
        {links.map((link, index) => (
          <li
            key={index}
            className={`page-item ${link.active ? 'active' : ''} ${!link.url ? 'disabled' : ''}`}
          >
            <button
              onClick={() => handlePageChange(link.url)}
              className="page-link"
              disabled={!link.url}
              dangerouslySetInnerHTML={{ __html: link.label }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
