import React from 'react';
import {Link} from 'react-router-dom';
import NotFoundImage from '../../images/404.svg'; // Update the path to your 404 image

function NotFoundPage() {
    return (
        <div style={{textAlign: 'center', marginTop: '50px'}}>
            <img src={NotFoundImage} alt="404 Not Found"
                 style={{maxWidth: '50%', height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
            <p style={{marginTop: '20px', fontSize: '18px', lineHeight: '1.6', color: '#666'}}>
                Oops, we can't find the page you are looking for. Go back to <Link to="/dashboard" style={{
                color: '#007bff',
                textDecoration: 'none'
            }}>dashboard</Link>.
            </p>
        </div>
    );
}

export default NotFoundPage;