import React from 'react';

const DevSignature = ({onClick, buttonText}) => {

    const handleLinkClick = () => {
        // CHECK ENV FOR URL
         window.open('https://onensensy.com', '_blank');
    };

    return (
        <div className="footer p-4 footer-small cursor-pointer">
            Design By Peter Wakoko. Developed by <span onClick={handleLinkClick}
                                                       class="text-primary-emphasis">//Sensy</span>
        </div>
    );
};

export default DevSignature;
