import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../helpers/API';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const performLogout = async () => {
            console.log('Logout');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('permissions');

            try {
                let response = await api('POST', 'logout');
                if (response.data.status) {
                    navigate('/');
                } else {
                    console.log(response.data.message);
                    navigate('/');
                }
            } catch (error) {
                console.error('Logout failed:', error);
                // Handle error, maybe navigate to an error page or display a message
                navigate('/');

            }
        };

        performLogout();
    }, [navigate]); // Dependency array, ensures effect runs once on component mount

    // Render nothing or a spinner while logging out
    return null;
};

export default Logout;
