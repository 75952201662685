import React from "react";
import {FaFolder} from "react-icons/fa";
import "../../css/UserGuide.css";

function UserGuideMainContent() {
    return (
        <div className="container mt-5 px-5">
            <section>
                {/*<h2>Introduction</h2>*/}
                <p>
                    Please contact the developer for any questions or issues. Thank you for using this application.
                </p>
            </section>
            {/*<section>*/}
            {/*    <h2>Getting Started</h2>*/}
            {/*    <p>*/}
            {/*        To get started, create an account or log in. Once logged in, you can*/}
            {/*        access your dashboard by clicking on the <FaFolder/> icon.*/}
            {/*    </p>*/}
            {/*</section>*/}
            {/*<section>*/}
            {/*    <h2>Features</h2>*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            <strong>Dashboard:</strong> Overview of your activities and quick*/}
            {/*            access to main features.*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <strong>Projects:</strong> Create, manage, and share your projects.*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <strong>Settings:</strong> Customize your experience according to*/}
            {/*            your preferences.*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</section>*/}
            {/*<section>*/}
            {/*    <h2>Troubleshooting</h2>*/}
            {/*    <p>*/}
            {/*        If you encounter any issues, please refer to our FAQs or contact*/}
            {/*        support.*/}
            {/*    </p>*/}
            {/*</section>*/}
            {/*<section>*/}
            {/*    <h2>FAQs</h2>*/}
            {/*    <dl>*/}
            {/*        <dt>How do I reset my password?</dt>*/}
            {/*        <dd>Go to settings and click on "Reset Password".</dd>*/}
            {/*        <dt>Can I share my projects with others?</dt>*/}
            {/*        <dd>Yes, projects can be shared directly from the project page.</dd>*/}
            {/*    </dl>*/}
            {/*</section>*/}
        </div>
    );
}

export default UserGuideMainContent;
