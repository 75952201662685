import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import "./css/App.css";
import LoginPage from "./pages/contexts/LoginPage";
import DashboardPage from "./pages/contexts/DashboardPage";
import CreateRecordPage from "./pages/contexts/CreateRecordPage";
import FolderPage from "./pages/contexts/FolderPage";
import NotFoundPage from "./pages/contexts/NotFoundPage";
import UserGuidePage from "./pages/contexts/UserGuidePage";
import AuthenticatedRoute from "./middlewares/AuthenticatedRoute";
import ViewRecordPage from "./pages/contexts/ViewRecordPage";
import SubParishPage from "./pages/contexts/SubParishPage";
import RecordPage from "./pages/contexts/RecordPage";
import Logout from "./components/Logout";
import "bootstrap/dist/css/bootstrap.min.css";
import ParishPage from "./pages/contexts/ParishPage";
import MoveFolderPage from "./pages/contexts/MoveFolderPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage/>}/>{" "}
                {/* Route for the LoginPage */}
                <Route
                    path="/dashboard"
                    element={
                        <AuthenticatedRoute>
                            <DashboardPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the DashboardPage */}
                <Route
                    path="/create-record"
                    element={
                        <AuthenticatedRoute>
                            <CreateRecordPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the CreateRecordPage */}
                <Route
                    path="/update-record/:id"
                    element={
                        <AuthenticatedRoute>
                            <CreateRecordPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the CreateRecordPage */}
                <Route
                    path="/view-record/:id"
                    element={
                        <AuthenticatedRoute>
                            <ViewRecordPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                <Route
                    path="/parish/:id"
                    element={
                        <AuthenticatedRoute>
                            <SubParishPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                <Route
                    path="/sub-parish/:id"
                    element={
                        <AuthenticatedRoute>
                            <FolderPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the LutiikoPage */}
                <Route path="*" element={<NotFoundPage/>}/>{" "}
                {/* Route for the NotFoundPage */}
                <Route
                    path="/logout"
                    element={
                        <AuthenticatedRoute>
                            <Logout/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the LuziiraPage */}
                <Route
                    path="/arch-deaconry/:id"
                    element={
                        <AuthenticatedRoute>
                            <ParishPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the Records */}
                <Route
                    path="/folder/:recordId/records/:id"
                    element={
                        <AuthenticatedRoute>
                            <RecordPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the OtherPage */}
                <Route
                    path="/user-guide"
                    element={
                        <AuthenticatedRoute>
                            <UserGuidePage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the UserGuidePage */}
                <Route
                    path="/move-folder"
                    element={
                        <AuthenticatedRoute>
                            <MoveFolderPage/>
                        </AuthenticatedRoute>
                    }
                />{" "}
                {/* Route for the UserGuidePage */}

            </Routes>

        </BrowserRouter>
    );
}

export default App;
