import React from 'react';

const LogoText = () => {
  return (
    <span style={{ 
      color: '#559BDC', 
      fontSize: '28px', 
      fontFamily: "'Poppins', sans-serif", 
      marginTop: '30px', 
      position: 'relative', 
      top: '1px', 
      fontWeight: 600,
    }}>
      NRMS
    </span>
  );
};

export default LogoText;