import React from 'react';
import {Navigate} from 'react-router-dom';

const isAuthenticated = () => {
    return sessionStorage.getItem('token') ? true : false;
};

const AuthenticatedRoute = ({children}) => {
    return isAuthenticated() ? children : <Navigate to="/"/>;
};
export default AuthenticatedRoute;