import React, {useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sign from '../../components/DevSignature';

import logoImage from "../../images/logo.svg";
import "../../css/LoginPage.css";
import {useNavigate} from "react-router-dom";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import api from "../../helpers/API";

const LoginPage = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLoginClick = async (event) => {
        event.preventDefault(); // Prevent the default form submit action
        try {
            // Then, make the login request with the email and password
            var response = await api("POST", "login", {
                email: email,
                password: password,
            });

            console.log(response);
            if (response.data.status) {
                console.log(response.data.data.token);
                //set token to session
                sessionStorage.setItem("token", response.data.data.token);
                sessionStorage.setItem('permissions', response.data.data.permissions);
                //set user details to session
                sessionStorage.setItem("user", JSON.stringify(response.data.data.user));
                navigate("/dashboard?title=Dashboard");
            } else {
                setError("Invalid credentials");
            }
        } catch (error) {
            console.error(
                "Error during login:",
                error.response ? error.response.data : error
            );
            return setError("Login failed. Please try again.");
        }
    };

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = (event) => {
        event.preventDefault(); // Prevent the default form submit action
        setPasswordShown(!passwordShown);
    };
    return (
        <div className="container-fluid h-100" style={{minHeight: "100vh"}}>
            <div className="row h-100">
                <div className="col-6 d-flex justify-content-center align-items-center background-image-container">
                    <div className="overlay"></div>
                    {/* Overlay added here */}
                </div>
                <div
                    className="col-6 d-flex justify-content-center align-items-center form-area"
                    style={{minHeight: "100vh"}}
                >
                    <form className="w-50" onSubmit={handleLoginClick}>
                        <div className="text-center mb-4">
                            <img
                                className="login-logo"
                                src={logoImage}
                                alt="Logo"
                                style={{maxWidth: "160px"}}
                            />
                        </div>
                        {error && <div className="login-error">{error}</div>}

                        <div className="form-group margin-bottom-md">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter your username"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="password">Password</label>
                            <div className="password-field">
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter your password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    onClick={(event) => togglePasswordVisibility(event)}
                                    className="toggle-password"
                                >
                                    {passwordShown ? <FaEyeSlash/> : <FaEye/>}
                                </button>
                            </div>
                        </div>
                        <div className="button-container mb-4">
                            <button
                                type="submit"
                                onClick={handleLoginClick}
                                className="btn btn-primary btn-sm btn-login"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                    <Sign/>
                </div>
            </div>
        </div>

    );
};

export default LoginPage;
