import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import api from "../helpers/API";

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState(" ");
    const user = JSON.parse(sessionStorage.getItem("user"));
    const {id} = useParams();

    const getHeading = (pathname) => {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        const titleFromParams = params.get("title");

        if (titleFromParams) {
            setTitle(titleFromParams);
        } else {
            const pathMap = {
                "/dashboard": "Dashboard",
                "/folders": "Folders",
                "/create-record": "Create Record",
                "/update-record": "Update Record",
                "/view-record": "Record Details",
                "/others": "Others",
                "/user-guide": "User Guide",
            };
            setTitle(pathMap[pathname] || "Unknown"); // Default to 'Unknown' if path is not found
        }
    };

    useEffect(() => {
        getHeading(location.pathname);
    }, [id, location.pathname]);

    const handleLogout = async () => {
        try {
            const response = await api("POST", "logout");
            if (response.data.status) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("user");
                navigate("/");
            } else {
                console.log("Logout failed with message:", response.data.message);
            }
        } catch (error) {
            console.error("Logout failed with error:", error);
        }
    };

    return (
        <div
            className="dashboard-header"
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 20px",
                height: "70px",
            }}
        >
            <h3>{title}</h3>
            <div>
        <span style={{marginRight: "20px"}}>
          <i className="fas fa-user" aria-hidden="true"></i> {user.name}
        </span>
                <button
                    onClick={handleLogout}
                    className="btn btn-outline-danger btn-sm"
                    title="Logout"
                >
                    <i className="fa fa-sign-out-alt" aria-hidden="true"></i> Logout
                </button>
            </div>
        </div>
    );
}

export default Header;