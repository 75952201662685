import React, {useEffect, useState} from "react";
import {FaFolder} from "react-icons/fa";
import "../../css/UserGuide.css";
import CreatableSelect from "react-select/creatable";
import api from "../../helpers/API";
import {useNavigate, useParams} from "react-router-dom";

function MoveFolderMainContent() {

    const [new_sub_parish, setNewSubParish] = useState(null);
    const [error, setError] = useState("");
    const [archDeaconryId, setArchDeaconryId] = useState(null);
    const [archDeaconries, setArchDeaconries] = useState([]);

    const [folders, setFolders] = useState([]);
    const [filteredFolders, setFilteredFolders] = useState([]); // For filtered folders
    const [folderId, setFolderId] = useState(null);
    const [folderName, setFolderName] = useState("");

    const [parishes, setParishes] = useState([]);
    const [parishId, setParishId] = useState(null);
    const [filteredParishes, setFilteredParishes] = useState([]);

    const [subParishes, setSubParishes] = useState([]);
    const [subParishId, setSubParishId] = useState(null);
    const [filteredSubParishes, setFilteredSubParishes] = useState([]);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);

    const {id} = useParams();

    const [submitLoader, setSubmitLoader] = useState(false);
    // Source Section
    const [sourceArchDeaconryId, setSourceArchDeaconryId] = useState(null);
    const [sourceParishId, setSourceParishId] = useState(null);
    const [sourceSubParishId, setSourceSubParishId] = useState(null);
    const [sourceFolderId, setSourceFolderId] = useState(null);

    const [sourceFilteredParishes, setSourceFilteredParishes] = useState([]);
    const [sourceFilteredSubParishes, setSourceFilteredSubParishes] = useState([]);
    const [sourceFilteredFolders, setSourceFilteredFolders] = useState([]);

// Destination Section
    const [destinationArchDeaconryId, setDestinationArchDeaconryId] = useState(null);
    const [destinationParishId, setDestinationParishId] = useState(null);
    const [destinationSubParishId, setDestinationSubParishId] = useState(null);
    const [destinationFilteredParishes, setDestinationFilteredParishes] = useState([]);
    const [destinationFilteredSubParishes, setDestinationFilteredSubParishes] = useState([]);

    const fetchDependencies = async () => {
        setLoading(true);
        try {
            let response;

            response = await api("GET", "records/create");


            if (response.data.status) {
                setArchDeaconries(response.data.data.arch_deaconries);
                setFolders(response.data.data.folders);
                setParishes(response.data.data.parishes);
                setSubParishes(response.data.data.sub_parishes);

            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDependencies();
    }, []);

    useEffect(() => {
        if (sourceArchDeaconryId && Array.isArray(parishes)) {
            setSourceFilteredParishes(parishes.filter(parish => parish.arch_deaconry_id === sourceArchDeaconryId));
        } else {
            setSourceFilteredParishes([]);
        }
        setSourceParishId(null);
        setSourceFilteredSubParishes([]);
        setSourceSubParishId(null);
        setSourceFilteredFolders([]);
    }, [sourceArchDeaconryId, parishes]);

    useEffect(() => {
        if (sourceParishId && Array.isArray(subParishes)) {
            setSourceFilteredSubParishes(subParishes.filter(subParish => subParish.parish_id === sourceParishId));
        } else {
            setSourceFilteredSubParishes([]);
        }
        setSourceSubParishId(null);
        setSourceFilteredFolders([]);
    }, [sourceParishId, subParishes]);

    useEffect(() => {
        if (sourceSubParishId && Array.isArray(folders)) {
            setSourceFilteredFolders(folders.filter(folder => folder.sub_parish_id === sourceSubParishId));
        } else {
            setSourceFilteredFolders([]);
        }
    }, [sourceSubParishId, folders]);

    useEffect(() => {
        if (destinationArchDeaconryId && Array.isArray(parishes)) {
            setDestinationFilteredParishes(parishes.filter(parish => parish.arch_deaconry_id === destinationArchDeaconryId));
        } else {
            setDestinationFilteredParishes([]);
        }
        setDestinationParishId(null);
        setDestinationFilteredSubParishes([]);
    }, [destinationArchDeaconryId, parishes]);

    useEffect(() => {
        if (destinationParishId && Array.isArray(subParishes)) {
            setDestinationFilteredSubParishes(subParishes.filter(subParish => subParish.parish_id === destinationParishId));
        } else {
            setDestinationFilteredSubParishes([]);
        }
        setDestinationSubParishId(null);
    }, [destinationParishId, subParishes]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoader(true); // Show the loader

        try {
            const formData = {
                new_sub_parish: destinationSubParishId
            };
            let response;
            console.log('request with', formData);
            console.log('folder with', sourceFolderId);

            response = await api("POST", `folders/${sourceFolderId}/move`, formData);

            if (response.data.status) {
                alert(response.data.message);
                //     Reset form
                setSourceArchDeaconryId(null);
                setDestinationArchDeaconryId(null);

                setSourceParishId(null);
                setDestinationParishId(null);

                setSourceSubParishId(null);
                setDestinationSubParishId(null);

                setSourceFolderId(null);

                fetchDependencies();

            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        } finally {
            setSubmitLoader(false); // Show the loader
        }
    };

    return (
        <div>
            {loading ? (
                <div className="container d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <div className="container mt-5">
                        <form onSubmit={handleSubmit} className="row">
                            <div className="row">
                                {/* Source Section */}
                                <div className="col-md-6">
                                    <h5>Source</h5>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="sourceArchDeaconry" className="form-label">Arch
                                                Deaconry</label>
                                            <CreatableSelect
                                                id="sourceArchDeaconry"
                                                value={sourceArchDeaconryId ? [{
                                                    value: sourceArchDeaconryId,
                                                    label: archDeaconries.find(deaconry => deaconry.id === sourceArchDeaconryId)?.name
                                                }] : []}
                                                onChange={selected => setSourceArchDeaconryId(selected?.value || null)}
                                                options={archDeaconries.map(deaconry => ({
                                                    value: deaconry.id,
                                                    label: deaconry.name
                                                }))}
                                                isClearable
                                                placeholder="Select Arch Deaconry"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="sourceParish" className="form-label">Parish</label>
                                            <CreatableSelect
                                                id="sourceParish"
                                                value={sourceParishId ? [{
                                                    value: sourceParishId,
                                                    label: sourceFilteredParishes.find(parish => parish.id === sourceParishId)?.name
                                                }] : []}
                                                onChange={selected => setSourceParishId(selected?.value || null)}
                                                options={sourceFilteredParishes.map(parish => ({
                                                    value: parish.id,
                                                    label: parish.name
                                                }))}
                                                isClearable
                                                placeholder="Select Parish"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="sourceSubParish" className="form-label">Sub Parish</label>
                                            <CreatableSelect
                                                id="sourceSubParish"
                                                value={sourceSubParishId ? [{
                                                    value: sourceSubParishId,
                                                    label: sourceFilteredSubParishes.find(subParish => subParish.id === sourceSubParishId)?.name
                                                }] : []}
                                                onChange={selected => setSourceSubParishId(selected?.value || null)}
                                                options={sourceFilteredSubParishes.map(subParish => ({
                                                    value: subParish.id,
                                                    label: subParish.name
                                                }))}
                                                isClearable
                                                placeholder="Select Sub Parish"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="sourceSubParish" className="form-label">Folder</label>
                                            <CreatableSelect
                                                id="sourceSubParish"
                                                value={sourceFolderId ? [{
                                                    value: sourceFolderId,
                                                    label: sourceFilteredFolders.find(folder => folder.id === sourceFolderId)?.name
                                                }] : []}
                                                onChange={selected => setSourceFolderId(selected?.value || null)}
                                                options={sourceFilteredFolders.map(folder => ({
                                                    value: folder.id,
                                                    label: folder.name
                                                }))}
                                                isClearable
                                                placeholder="Select Folder"
                                            />
                                        </div>

                                    </form>
                                </div>

                                {/* Destination Section */}
                                <div className="col-md-6">
                                    <h5>Destination</h5>

                                    <div className="mb-3">
                                        <label htmlFor="destinationArchDeaconry" className="form-label">Arch
                                            Deaconry</label>
                                        <CreatableSelect
                                            id="destinationArchDeaconry"
                                            value={destinationArchDeaconryId ? [{
                                                value: destinationArchDeaconryId,
                                                label: archDeaconries.find(deaconry => deaconry.id === destinationArchDeaconryId)?.name
                                            }] : []}
                                            onChange={selected => setDestinationArchDeaconryId(selected?.value || null)}
                                            options={archDeaconries.map(deaconry => ({
                                                value: deaconry.id,
                                                label: deaconry.name
                                            }))}
                                            isClearable
                                            placeholder="Select Arch Deaconry"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="destinationParish" className="form-label">Parish</label>
                                        <CreatableSelect
                                            id="destinationParish"
                                            value={destinationParishId ? [{
                                                value: destinationParishId,
                                                label: destinationFilteredParishes.find(parish => parish.id === destinationParishId)?.name
                                            }] : []}
                                            onChange={selected => setDestinationParishId(selected?.value || null)}
                                            options={destinationFilteredParishes.map(parish => ({
                                                value: parish.id,
                                                label: parish.name
                                            }))}
                                            isClearable
                                            placeholder="Select Parish"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="destinationSubParish" className="form-label">Sub Parish</label>
                                        <CreatableSelect
                                            id="destinationSubParish"
                                            value={destinationSubParishId ? [{
                                                value: destinationSubParishId,
                                                label: destinationFilteredSubParishes.find(subParish => subParish.id === destinationSubParishId)?.name
                                            }] : []}
                                            onChange={selected => setDestinationSubParishId(selected?.value || null)}
                                            options={destinationFilteredSubParishes.map(subParish => ({
                                                value: subParish.id,
                                                label: subParish.name
                                            }))}
                                            isClearable
                                            placeholder="Select Sub Parish"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" className="btn btn-primary btn-block"
                                                disabled={submitLoader}>
                                            {submitLoader ? (
                                                <i className="fas fa-spinner fa-spin"></i>
                                            ) : (
                                                id ? "Update Record" : "Move Folder"
                                            )}
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </form>
                    </div>
                </>

            )}
        </div>

    );
}

export default MoveFolderMainContent;
