import React, {useEffect, useState} from "react";
import Select from 'react-select';  // Import react-select
import CreatableSelect from 'react-select/creatable';
import "../../css/CreateRecordMainContent.css";
import api from "../../helpers/API";
import {useNavigate, useParams} from "react-router-dom";

function CreateRecordMainContent() {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [error, setError] = useState("");
    const [archDeaconryId, setArchDeaconryId] = useState(null);
    const [leaseNo, setLeaseNo] = useState("");
    const [name, setName] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [location, setLocation] = useState("");
    const [groundRent, setGroundRent] = useState("");
    const [dateLeased, setDateLeased] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [path, setPath] = useState("");
    const [archDeaconries, setArchDeaconries] = useState([]);

    const [folders, setFolders] = useState([]);
    const [filteredFolders, setFilteredFolders] = useState([]); // For filtered folders
    const [folderId, setFolderId] = useState(null);
    const [folderName, setFolderName] = useState("");

    const [parishes, setParishes] = useState([]);
    const [parishId, setParishId] = useState(null);
    const [filteredParishes, setFilteredParishes] = useState([]);

    const [subParishes, setSubParishes] = useState([]);
    const [subParishId, setSubParishId] = useState(null);
    const [filteredSubParishes, setFilteredSubParishes] = useState([]);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const DEFAULT_IMAGE = "https://via.placeholder.com/600";

    const {id} = useParams();

    const fetchDependencies = async () => {
        setLoading(true);
        try {
            let response;
            if (id) {
                response = await api("GET", `records/${id}/edit`);
            } else {
                response = await api("GET", "records/create");
            }

            if (response.data.status) {
                setArchDeaconries(response.data.data.arch_deaconries);
                setFolders(response.data.data.folders);
                setParishes(response.data.data.parishes);
                setSubParishes(response.data.data.sub_parishes);

                if (id) {
                    setArchDeaconryId(response.data.data.data.arch_deaconry_id);
                    setParishId(response.data.data.data.parish_id);
                    setSubParishId(response.data.data.data.sub_parish_id);
                    setFolderId(response.data.data.data.folder_id);
                    setLeaseNo(response.data.data.data.lease_no);
                    setName(response.data.data.data.name);
                    setDocumentType(response.data.data.data.document_type);
                    setLocation(response.data.data.data.location);
                    setGroundRent(response.data.data.data.ground_rent);
                    setDateLeased(response.data.data.data.date_leased);
                    setExpiryDate(response.data.data.data.expiry_date);
                    setPath(response.data.data.data.path);
                }
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDependencies();
    }, [id]);

    /**
     * Filter the folders based on the selected arch deaconry
     */
    useEffect(() => {
        if (archDeaconryId && Array.isArray(parishes)) {
            setFilteredParishes(parishes.filter(parish => parish.arch_deaconry_id === archDeaconryId));
        } else {
            setFilteredParishes([]);
        }
        setParishId(null);
        setFilteredSubParishes([]);
        setSubParishId(null);
        setFilteredFolders([]);
        setFolderId(null);
    }, [archDeaconryId, parishes]);

    useEffect(() => {
        if (parishId && Array.isArray(subParishes)) {
            setFilteredSubParishes(subParishes.filter(subParish => subParish.parish_id === parishId));
        } else {
            setFilteredSubParishes([]);
        }
        setSubParishId(null);
        setFilteredFolders([]);
        setFolderId(null);
    }, [parishId, subParishes]);

    useEffect(() => {
        if (subParishId && Array.isArray(folders)) {
            setFilteredFolders(folders.filter(folder => folder.sub_parish_id === subParishId));
        } else {
            setFilteredFolders([]);
        }
        setFolderId(null);
    }, [subParishId, folders]);

    const [uploadLoader, setUploadLoader] = useState(false);


    const [fileError, setFileError] = useState(''); // State for file error message

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileError('');

            if (file.size > MAX_FILE_SIZE) {
                setFileError('File uploaded but too large to preview.');
                setUploadedFile(null);
            }

            setUploadLoader(true);

            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedFile(reader.result); // Set file preview if size is valid
                setFileType(file.type);
                setUploadLoader(false);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleUploadClick = () => {
        document.getElementById("documentUpload").click();
    };

    const handleCreateFolder = async (inputValue) => {
        try {
            const response = await api("POST", "folders", {
                name: inputValue,
                arch_deaconry_id: archDeaconryId,
                parish_id: parishId,
                sub_parish_id: subParishId
            });
            if (response.data.status) {
                alert(response.data.message);
                fetchDependencies();
                setFolderId(response.data.data.id);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        }
    };

    const handleCreateParish = async (inputValue) => {
        try {
            const response = await api("POST", "parishes", {name: inputValue, arch_deaconry_id: archDeaconryId});
            if (response.data.status) {
                alert(response.data.message);
                // setFolderName("");
                fetchDependencies();
                setParishId(response.data.data.id);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        }
    };

    const handleCreateArchDeaconry = async (inputValue) => {
        try {
            const response = await api("POST", "arch-deaconries", {name: inputValue});
            if (response.data.status) {
                alert(response.data.message);
                fetchDependencies();
                setArchDeaconryId(response.data.data.id);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        }
    };

    const handleCreateSubParish = async (inputValue) => {
        try {
            const response = await api("POST", "sub-parishes", {
                name: inputValue,
                arch_deaconry_id: archDeaconryId,
                parish_id: parishId
            });
            if (response.data.status) {
                alert(response.data.message);
                fetchDependencies();
                setSubParishId(response.data.data.id);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        }
    };

    const [submitLoader, setSubmitLoader] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoader(true); // Show the loader

        try {
            const formData = {
                arch_deaconry_id: archDeaconryId,
                folder_id: folderId,
                parish_id: parishId,
                sub_parish_id: subParishId,
                lease_no: leaseNo,
                name,
                document_type: documentType,
                location,
                ground_rent: groundRent,
                date_leased: dateLeased,
                expiry_date: expiryDate,
                path: uploadedFile
            };
            let response;
            if (id) {
                response = await api("PUT", `records/${id}`, formData);
            } else {
                response = await api("POST", "records", formData);
            }
            if (response.data.status) {
                alert(response.data.message);
                setUploadedFile(null);

                // Get the folder name
                const folder = folders.find(folder => folder.id === folderId);
                const folderName = folder ? folder.name : "";


                navigate(archDeaconryId && folderId
                    ? `/folder/${archDeaconryId}/records/${folderId}?title=${folderName}`
                    : "/dashboard?title='Dashboard'");
                setFileType("");
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Error: " + error.message);
        } finally {
            setSubmitLoader(false); // Show the loader
        }
    };

    return (
        <div>
            {loading ? (
                <div className="container d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <div className="container">
                        <div className="row mt-4 pt-4 mb-4">
                            <div className="col-md-6" style={{height: "80vh"}}>
                                <div className="upload-section text-center" style={{flex: "1", overflow: "hidden"}}>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="documentUpload"
                                        style={{display: "none"}}
                                        onChange={handleFileChange}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={handleUploadClick}
                                        disabled={uploadLoader} // Disable button while loading
                                    >
                                        {uploadLoader ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin"></i> Uploading...
                                            </>
                                        ) : (
                                            <>
                                                <i className="fas fa-upload"></i> Upload Document
                                            </>
                                        )}
                                    </button>
                                    {(path || uploadedFile) && (
                                        <div className="preview-section mt-3">
                                            {fileError ? (
                                                <p>{fileError}</p>
                                            ) : (
                                                (uploadedFile || path) && (
                                                    <>
                                                        {/* Check `uploadedFile` first, then `path` */}
                                                        {uploadedFile?.startsWith("data:application/pdf") || (path && new URL(path).pathname.endsWith(".pdf")) ? (
                                                            // PDF Preview
                                                            <iframe
                                                                src={uploadedFile || path} // Prioritize `uploadedFile`
                                                                style={{width: "600px", height: "600px"}}
                                                                title="PDF Preview"
                                                            />
                                                        ) : uploadedFile?.startsWith("data:image/") || (path && (new URL(path).pathname.endsWith(".jpg") || new URL(path).pathname.endsWith(".jpeg") || new URL(path).pathname.endsWith(".png"))) ? (
                                                            // Image Preview
                                                            <img
                                                                src={uploadedFile || path} // Prioritize `uploadedFile`
                                                                style={{
                                                                    width: "600px",
                                                                    height: "600px",
                                                                    objectFit: "contain"
                                                                }}
                                                                alt="Image Preview"
                                                            />
                                                        ) : uploadedFile?.startsWith("data:video/") || (path && (new URL(path).pathname.endsWith(".mp4") || new URL(path).pathname.endsWith(".webm"))) ? (
                                                            // Video Preview
                                                            <video
                                                                src={uploadedFile || path} // Prioritize `uploadedFile`
                                                                controls
                                                                style={{
                                                                    width: "600px",
                                                                    height: "600px",
                                                                    objectFit: "contain"
                                                                }}
                                                            >
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            // Default Fallback Image for Unsupported Types
                                                            <img
                                                                src={DEFAULT_IMAGE}
                                                                style={{
                                                                    width: "600px",
                                                                    height: "600px",
                                                                    objectFit: "contain"
                                                                }}
                                                                alt="Default Preview"
                                                            />
                                                        )}
                                                        <a
                                                            href={uploadedFile || path} // Download link prioritizes `uploadedFile`
                                                            download
                                                            style={{
                                                                display: 'block',
                                                                marginTop: '10px',
                                                                textDecoration: 'none',
                                                                color: 'blue'
                                                            }}
                                                        >
                                                            Download File
                                                        </a>
                                                    </>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6" style={{flex: "1", padding: "10px", overflowY: "auto"}}>
                                <form onSubmit={handleSubmit} className="row">
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="arch_deaconry_id" className="form-label">
                                            Arch Deaconry
                                        </label>
                                        <CreatableSelect
                                            id="arch_deaconry_id"
                                            value={archDeaconryId ? [{
                                                value: archDeaconryId,
                                                label: archDeaconries.find(deaconry => deaconry.id === archDeaconryId)?.name
                                            }] : []}
                                            onChange={(selectedOption) => {
                                                console.log('Selected Arch Deaconry', selectedOption);
                                                setArchDeaconryId(selectedOption ? selectedOption.value : null);
                                            }}
                                            onCreateOption={handleCreateArchDeaconry}
                                            options={archDeaconries.map(deaconry => ({
                                                value: deaconry.id,
                                                label: `${deaconry.name}`
                                            }))}
                                            isClearable
                                            placeholder="Select or Create Arch Deaconry"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="parish_id" className="form-label">
                                            Parish
                                        </label>
                                        <CreatableSelect
                                            id="parish_id"
                                            value={parishId ? [{
                                                value: parishId,
                                                label: filteredParishes.find(parish => parish.id === parishId)?.name
                                            }] : []}
                                            onChange={(selectedOption) => {
                                                console.log('Selected Parish', selectedOption);
                                                setParishId(selectedOption ? selectedOption.value : null);
                                            }}
                                            onCreateOption={handleCreateParish}
                                            options={filteredParishes.map(parish => ({
                                                value: parish.id,
                                                label: `${parish.name}`
                                            }))}
                                            isClearable
                                            placeholder="Select or Create Parish"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="sub_parish_id" className="form-label">
                                            Sub Parish
                                        </label>
                                        <CreatableSelect
                                            id="sub_parish_id"
                                            value={subParishId ? [{
                                                value: subParishId,
                                                label: filteredSubParishes.find(subParish => subParish.id === subParishId)?.name
                                            }] : []}
                                            onChange={(selectedOption) => {
                                                console.log('Selected Sub Parish', selectedOption);
                                                setSubParishId(selectedOption ? selectedOption.value : null);
                                            }}
                                            onCreateOption={handleCreateSubParish}
                                            options={filteredSubParishes.map(subParish => ({
                                                value: subParish.id,
                                                label: `${subParish.name}`
                                            }))}
                                            isClearable
                                            placeholder="Select or Create Sub Parish"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="folder_id" className="form-label">
                                            Folder
                                        </label>
                                        <CreatableSelect
                                            id="folder_id"
                                            value={folderId ? [{
                                                value: folderId,
                                                label: filteredFolders.find(folder => folder.id === folderId)?.name
                                            }] : []}
                                            onChange={(selectedOption) => {
                                                console.log('Selected Folder', selectedOption);
                                                setFolderId(selectedOption ? selectedOption.value : null);
                                            }}
                                            onCreateOption={handleCreateFolder}
                                            options={filteredFolders.map(folder => ({
                                                value: folder.id,
                                                label: `${folder.name}`
                                            }))}
                                            isClearable
                                            placeholder="Select or Create Folder"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <label htmlFor="name" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter full name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="lease_no" className="form-label">
                                            Lease No
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lease_no"
                                            placeholder="Enter lease number"
                                            value={leaseNo}
                                            onChange={(e) => setLeaseNo(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="document_type" className="form-label">
                                            Document Type
                                        </label>
                                        <select
                                            className="form-select"
                                            id="document_type"
                                            value={documentType}
                                            onChange={(e) => setDocumentType(e.target.value)}
                                        >
                                            <option value="">Select Document Type</option>
                                            <option value="lease">Lease</option>
                                            <option value="rent">Rent</option>
                                            <option value="others">Other</option>
                                        </select>
                                    </div>
                                    {/*<div className="col-md-6 mt-2">*/}
                                    {/*    <label htmlFor="location" className="form-label">*/}
                                    {/*        Location*/}
                                    {/*    </label>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="location"*/}
                                    {/*        placeholder="Enter location"*/}
                                    {/*        value={location}*/}
                                    {/*        onChange={(e) => setLocation(e.target.value)}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="ground_rent" className="form-label">
                                            Ground Rent
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="ground_rent"
                                            placeholder="Enter ground rent"
                                            value={groundRent}
                                            onChange={(e) => setGroundRent(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="date_leased" className="form-label">
                                            Date Leased
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="date_leased"
                                            placeholder="Enter date leased"
                                            value={dateLeased}
                                            onChange={(e) => setDateLeased(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label htmlFor="expiry_date" className="form-label">
                                            Expiry Date
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="expiry_date"
                                            placeholder="Enter expiry date"
                                            value={expiryDate}
                                            onChange={(e) => setExpiryDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" className="btn btn-primary" disabled={submitLoader}>
                                            {submitLoader ? (
                                                <i className="fas fa-spinner fa-spin"></i>
                                            ) : (
                                                id ? "Update Record" : "Create Record"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default CreateRecordMainContent;
