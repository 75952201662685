import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import api from "../../helpers/API";
import {useParams} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import {FaFolder} from "react-icons/fa";
import {FaRegFilePdf} from "react-icons/fa6";

function RecordMainContent() {
    const [data, setData] = useState([]);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [links, setLinks] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const {id} = useParams();

    const fetchRecords = async (page) => {
        setLoading(true);
        try {
            var url =
                page === 1
                    ? `folder/${id}/records`
                    : `folder/${id}/records?page=${page}`;
            if (search !== "") {
                url = `folder/${id}/records?query=${search}&page=${page}`;
            }

            const response = await api("GET", url);
            if (response.data.status) {
                const {listings} = response.data.data;
                setData(listings);
                setListings(listings.data);
                setCurrentPage(listings.current_page);
                setLastPage(listings.last_page);
                setLinks(listings.links);
                setTotal(listings.total);
            } else {
                setError(response.data.message);
                setCurrentPage(1);
                setLastPage(1);
                setLinks([]);
                setTotal(0);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecords(currentPage);
    }, [currentPage, id, search]);

    // useEffect(()=>{
    //   setCurrentPage(1);
    //   fetchRecords(currentPage);
    // },[id]);
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mt-4 px-4">
            <div className="mt-4">
                <div class="form-outline" data-mdb-input-init>
                    <input
                        type="search"
                        id="search"
                        className="form-control"
                        placeholder="Search record"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            {loading ? (
                <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{height: "80vh"}}
                >
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <table className="table table-hover">
                        <thead className="table-primary">
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Created at</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(listings) &&
                            listings.map((record) => (
                                <tr key={record.id}>
                                    <td>
                                        <FaRegFilePdf size={20} color="primary"/>
                                    </td>
                                    <td>{record.name}</td>
                                    <td>{record.document_type ?? '--'}</td>
                                    <td>{record.created_at}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            className="action-icon view"
                                            title="View"
                                            onClick={() => {
                                                navigate(
                                                    "/view-record/" +
                                                    record.id +
                                                    "?title=Record Details"
                                                );
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className="action-icon edit"
                                            title="Update"
                                            onClick={() => {
                                                navigate(
                                                    "/update-record/" +
                                                    record.id +
                                                    "?title=Update Record"
                                                );
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="action-icon delete"
                                            title="Delete"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        links={links}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
        </div>
    );
}

export default RecordMainContent;
