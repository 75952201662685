import React, {useState, useEffect} from "react";
import {FaFolder} from "react-icons/fa";
import "../../css/DashboardPage.css";
import api from "../../helpers/API";
import {Link} from "react-router-dom";

function DashboardMainContent() {
    // State to store folders fetched from API
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch folders from API on component mount
    useEffect(() => {
        const fetchFolders = async () => {
            setLoading(true);
            try {
                const response = await api("GET", "arch-deaconries");
                // Function to generate a random color in hexadecimal format
                const generateRandomColor = () => {
                    return "#" + Math.floor(Math.random() * 16777215).toString(16);
                };

                if (response.data.status) {
                    console.log(response.data.data.listings);
                    // Map through the folders and assign a random color to each
                    const foldersWithColors = response.data.data.listings.map(
                        (folder) => ({
                            ...folder,
                            color: generateRandomColor(), // Assign a random color
                        })
                    );
                    setFolders(foldersWithColors); // Update state with folders that now include color information
                } else {
                    // setError('response.data.message'); // Placeholder for setting error state
                }
            } catch (error) {
                console.error("Failed to fetch folders:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFolders();
    }, []); // Empty dependency array to run the effect only once

    return (
        <div className="container  mt-5 px-5">
            {loading ? (
                <div
                    className="container d-flex justify-content-center align-items-center"
                    style={{height: "80vh"}}
                >
                    <div className="loader"></div>
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    <h4>Arch deaconries</h4>
                    <div className="folders-container">
                        {folders.map((folder, index) => (
                            <Link
                                className="dashboard-link"
                                key={folder.id}
                                to={"/arch-deaconry/" + folder.id + "?title=" + folder.name}
                            >
                                {" "}
                                {/* Move key here */}
                                <div className="folder">
                                    <FaFolder size={120} color={folder.color}/>
                                    <div className="folder-info">
                                        <h2>{folder.name}</h2>
                                        <p>{folder.records}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default DashboardMainContent;
