import React, {useState, useEffect} from 'react';
import Sign from '../../components/DevSignature';
import '../../css/DashboardPage.css';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import DashboardMainContent from '../contents/DashboardMainContent';
import DashboardContext from './DashboardContext'; // Import the context

function DashboardPage() {
    const [dashboardClass, setDashboardClass] = useState('dashboard');

    useEffect(() => {
        // Load the dashboard class from localStorage when the component mounts
        const savedClass = localStorage.getItem('dashboardClass');
        if (savedClass) {
            setDashboardClass(savedClass);
        }
    }, []);

    const changeDashboardClass = () => {
        setDashboardClass(currentClass => {
            const newClass = currentClass === 'dashboard' ? 'dashboard-change' : 'dashboard';
            // Save the new class to localStorage
            localStorage.setItem('dashboardClass', newClass);
            return newClass;
        });
    };

    return (
        <DashboardContext.Provider value={{changeDashboardClass}}>
            <div className={dashboardClass}>
                <Sidebar/>
                <div className="dashboard-main">
                    <Header/>
                    <DashboardMainContent/>
                    <Sign/>
                </div>
            </div>
        </DashboardContext.Provider>
    );
}

export default DashboardPage;