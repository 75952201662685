import React, {useState, useEffect} from "react";
import "../../css/DashboardPage.css";
import Sign from "../../components/DevSignature";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import SubParishMainContent from "../contents/SubParishMainContent";
import DashboardContext from "./DashboardContext"; // Import the context
import {useParams} from "react-router-dom";
import ParishMainContent from "../contents/ParishMainContent";

function SubParishPage() {
    let {id} = useParams();
    const [dashboardClass, setDashboardClass] = useState("dashboard");

    useEffect(() => {
        // Load the dashboard class from localStorage when the component mounts
        const savedClass = localStorage.getItem("dashboardClass");
        if (savedClass) {
            setDashboardClass(savedClass);
        }
    }, []);

    const changeDashboardClass = () => {
        setDashboardClass((currentClass) => {
            const newClass =
                currentClass === "dashboard" ? "dashboard-change" : "dashboard";
            // Save the new class to localStorage
            localStorage.setItem("dashboardClass", newClass);
            return newClass;
        });
    };

    return (
        <DashboardContext.Provider value={{changeDashboardClass}}>
            <div className={dashboardClass}>
                <Sidebar/>
                <div className="dashboard-main">
                    <Header/>
                    <SubParishMainContent/>
                    <Sign/>
                </div>
            </div>
        </DashboardContext.Provider>
    );
}

export default SubParishPage;
